import Navbar from "../../components/navbar/navbar.jsx";


const Dashboard = () => {
   return <>
       <Navbar />

   </>
};

export default Dashboard;