import React, { useState, useEffect } from "react";
import Navbar from "../../components/navbar/navbar.jsx";
import { api } from "../../services/api.js";
import FabricanteBox from "../../components/combobox/fabricantebox.js";
import { EstabelecimentoTodos } from "../../components/combobox/estabelecimento.js";
import { VendedoresTodos } from "../../components/combobox/vendedores.js";
import { TelevendasTodos } from "../../components/combobox/televendas.js";
import GrpClientes from "../../components/combobox/grpcliente.js";
import { RelatorioVendas, RelatorioVendasHead } from "../../components/relatoriovendas/relatorioVendas.js";

const RelatVendas = () => {
    //paramentros
    const [listafabricantes, setListaFabricantes] = useState([]);
    const [selEstabe, setSelEstabe] = useState("")
    const [selVende, setSelVende] = useState("")
    const [selTele, setSelTele] = useState("")
    const [selGrpCli, setSelGrpCli] = useState("")
    const [selDataIni, setSelDataIni] = useState(new Date())
    const [selDataFim, setSelDataFim] = useState(new Date())
    const [selCliente, setSelCliente] = useState("")
    const [selTipo, setSelTipo] = useState(0)

    //dados relatorio
    const [dadosRelatorio, setDadosRelatorio] = useState([]);
    const [relat, setRelat] = useState([])
    const [somaVlrTotal, setSomaVlrTotal] = useState(0)
    const [somaUndTotal, setSomaUndTotal] = useState(0)

    const sortTable = (sortColumn) => {
        let rel = relat
        switch (sortColumn) {
            case 0:
                switch (parseInt(Object(selTipo))) {
                    case 1: rel = rel.sort((a, b) => a.Televendas > b.Televendas ? 1 : -1)
                        break;
                    case 2: rel = rel.sort((a, b) => a.Vendedor > b.Vendedor ? 1 : -1)
                        break;
                    case 3: rel = rel.sort((a, b) => a.Televendas > b.Televendas ? 1 : -1)
                        break;
                    default: rel = rel.sort((a, b) => a.Vendedor > b.Vendedor ? 1 : -1)
                        break;
                }
                break;
            default: { rel.sort((a, b) => a.part < b.part ? 1 : -1)}
                break;
        }
        setRelat(rel)
    }

    const GerarRelatorio = async () => {

        let params = {
            cod_estabe: selEstabe,
            dtInicio: selDataIni,
            dtFim: selDataFim,
            grpcli: selGrpCli,
            codcli: selCliente,
            vende: selVende,
            tele: selTele
        }
        await api.get("/relatorio/vendasvendtele", { params })
            .then((ret) => {
                setDadosRelatorio(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar Pedidos! " + err)
            })
    }

    const RenderizarRelatorio = () => {
        return <>
            <thead>
                <RelatorioVendasHead tipo={selTipo}></RelatorioVendasHead>
            </thead>

            <tbody>
                {relat.map((a, b) => <RelatorioVendas props={a} tipo={selTipo}></RelatorioVendas>)}
            </tbody>
        </>
    }

    useEffect(() => {
        let rel = []
        let achou = false
        let total = 0
        let und = 0
        for (let i = 0; i < dadosRelatorio.length; i++) {
            achou = false
            total += dadosRelatorio[i].Valor
            und += dadosRelatorio[i].Unidades
            for (let x = 0; x < rel.length; x++) {
                switch (parseInt(Object(selTipo))) {
                    case 1:
                        if (dadosRelatorio[i].Televendas === rel[x].Televendas) {
                            achou = true
                            rel[x].vlr += dadosRelatorio[i].Valor
                            rel[x].qtd += dadosRelatorio[i].Unidades
                        }
                        break;
                    case 2:
                        if ((dadosRelatorio[i].Vendedor === rel[x].Vendedor) && (dadosRelatorio[i].Fabricante === rel[x].Fabricante)) {
                            achou = true
                            rel[x].vlr += dadosRelatorio[i].Valor
                            rel[x].qtd += dadosRelatorio[i].Unidades
                        }
                        break;
                    case 3:
                        if ((dadosRelatorio[i].Televendas === rel[x].Televendas) && (dadosRelatorio[i].Fabricante === rel[x].Fabricante)) {
                            achou = true
                            rel[x].vlr += dadosRelatorio[i].Valor
                            rel[x].qtd += dadosRelatorio[i].Unidades
                        }
                        break;
                    default:
                        if (dadosRelatorio[i].Vendedor === rel[x].Vendedor) {
                            achou = true
                            rel[x].vlr += dadosRelatorio[i].Valor
                            rel[x].qtd += dadosRelatorio[i].Unidades
                        }
                        break;
                }
                if (achou) break
            }
            if (!achou) {
                switch (parseInt(Object(selTipo))) {
                    case 1: rel.push({ Televendas: dadosRelatorio[i].Televendas, qtd: dadosRelatorio[i].Unidades, vlr: dadosRelatorio[i].Valor, part: 0.00 })

                        break;
                    case 2: rel.push({ Vendedor: dadosRelatorio[i].Vendedor, Fabricante: dadosRelatorio[i].Fabricante, qtd: dadosRelatorio[i].Unidades, vlr: dadosRelatorio[i].Valor, part: 0.00 })

                        break;
                    case 3: rel.push({ Televendas: dadosRelatorio[i].Televendas, Fabricante: dadosRelatorio[i].Fabricante, qtd: dadosRelatorio[i].Unidades, vlr: dadosRelatorio[i].Valor, part: 0.00 })

                        break;
                    default: rel.push({ Vendedor: dadosRelatorio[i].Vendedor, qtd: dadosRelatorio[i].Unidades, vlr: dadosRelatorio[i].Valor, part: 0.00 })
                        break;
                }

            }
        }
        //calcular participação
        for (let x = 0; x < rel.length; x++) {
            rel[x].part = (rel[x].vlr / total)
        }

        if (rel.length > 0) {
            switch (parseInt(Object(selTipo))) {
                case 1: rel = rel.sort((a, b) => a.Televendas > b.Televendas ? 1 : -1)
                    break;
                case 2: rel = rel.sort((a, b) => a.Vendedor > b.Vendedor ? 1 : -1)
                    break;
                case 3: rel = rel.sort((a, b) => a.Televendas > b.Televendas ? 1 : -1)
                    break;
                default: rel = rel.sort((a, b) => a.Vendedor > b.Vendedor ? 1 : -1)
                    break;
            }

            setRelat(rel)
            setSomaUndTotal(und)
            setSomaVlrTotal(total)
        }

    }, [dadosRelatorio, selTipo])

    const addDays = (days, date, setDate) => {
        const newDate = new Date(date.getTime());
        newDate.setDate(date.getDate() + days);
        setDate(newDate.toISOString().slice(0, 10));
    };

    useEffect(()=>{
        const table = document.querySelector('table'); //get the table to be sorted

        table.querySelectorAll('th') // get all the table header elements
            .forEach((element, columnNo) => { // add a click handler for each 
                element.addEventListener('click', event => {
                    sortTable(columnNo); //call a function which sorts the table by a given column number
                })
            })
    },[RenderizarRelatorio])

    useEffect(() => {
        let params = {
            cod_vendedor: selVende
        }
        api.get("/listar/fabricantes", params)
            .then((ret) => {
                setListaFabricantes(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar fabricantes! " + err);
            })

        addDays(-30, new Date(), setSelDataIni)
        setSelDataFim(new Date(Date.now()).toISOString().slice(0, 10))
    }, [])

    return <>
        <Navbar />
        <div className="container-fluid">
            <div className="d-flex justify-content-center">
                <h2>Relatorio de Vendas</h2>
            </div>
            <div className=" ms-4 me-4">
                <div className="ps-1 pt-1 pe-1 border border-black">
                    <div className="collapse" id="navbarToggleExternalContent" data-bs-theme="green">
                        <div className="p-2">
                            <div className="row mb-1">

                                <div className="col-md-4">
                                    <label htmlFor="dtInicio" className="me-2">Data Inicial</label>
                                    <input type="date" name="dtInicio" id="dtInicio" value={selDataIni} onChange={(e) => setSelDataIni(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="dtInicio" className="me-2">Data Final</label>
                                    <input type="date" name="dtFim" id="dtFim" value={selDataFim} onChange={(e) => setSelDataFim(e.target.value)} />
                                </div>
                                <div className="col-md-4">
                                    <div className="dropdown">
                                        <label htmlFor="dtInicio" className="me-2">Fabricante</label>
                                        <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                                            Selecione
                                        </button>
                                        <form className="dropdown-menu p-0">
                                            <div className="mb-0">
                                                {
                                                    listafabricantes.map((fabric, index) => {
                                                        return <FabricanteBox key={fabric.codigo} CODIGO={fabric.CODIGO}
                                                            FANTASIA={fabric.FANTASIA} />
                                                    })
                                                }

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-4">
                                    <label htmlFor="selectVendedor" className="me-2">Vendedor</label>
                                    <VendedoresTodos setSelVende={setSelVende} />
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="selectTele" className="me-2">Televendas</label>
                                    <TelevendasTodos setSelTele={setSelTele} />
                                </div>
                                <div className="col-md-4">
                                    <label htmlFor="selectEstab" className="me-2">Estado</label>
                                    <EstabelecimentoTodos setSelEstabe={setSelEstabe}></EstabelecimentoTodos>
                                </div>
                            </div>
                            <div className="row mb-0">
                                {/* <div className="col-md-4">
                                    <label htmlFor="tbCliente" className="me-2">Cliente</label>
                                    <input type="text" name="tbCliente" id="tbCliente" value={selCliente} onChange={(e) => setSelCliente(e.target.value)} />
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="selectTele" className="me-2">Grp Cli.</label>
                                    <GrpClientes setSelGrpCli={setSelGrpCli}></GrpClientes>
                                </div> */}
                                <div className="col-md-4">
                                    <label htmlFor="inputProduto" className="me-2">Tipo</label>
                                    <select name="selTipo" id="selTipo" value={selTipo} onChange={(e) => { setSelTipo(e.target.value); RenderizarRelatorio() }}>
                                        <option value="0">Vendedor</option>
                                        <option value="1">Televendas</option>
                                        <option value="2">Vendedor Fabricante</option>
                                        <option value="3">Televendas fabricante</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar">
                        <div className="container-fluid md-1">

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </button>
                            <div>
                                <label id="lbTotal">Unidades : {somaUndTotal} - Valor : {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(somaVlrTotal)}</label>
                            </div>
                            <button type="submit" className="btn btn-lg btn-primary" onClick={(e) => GerarRelatorio()}>Gerar Relatório</button>
                        </div>
                    </nav>
                </div>
                {/* inserir relatorio aqui */}
                <table id="table" className="table table-hover">
                    {RenderizarRelatorio()}
                </table>
            </div>
        </div >


    </>
}

export default RelatVendas;