import React, {useState, useContext} from "react";

import { AuthContext } from "../../contexts/auth";

import "./login.css"
import logo from "../../assets/logo.png"
const Login = () => {
    const {login} = useContext(AuthContext);

    const [cpf, setCpf] = useState("");
    const [senha, setSenha] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        login(cpf, senha);
    }

    return (
        <div id="login">
            <form className="form" onSubmit={handleSubmit}>
            <img id="logo" src={logo} className="rounded mx-auto d-block w-100 p-4" alt="Logo"></img>
                <div className="title">
                    <h3>Login do sistema</h3>
                </div>
                <div className="field">
                    <label htmlFor="cpf">CPF</label>
                    <input type="number" name="cpf" id="cpf" value={cpf} onChange={(e) => setCpf(e.target.value)}/>
                </div>
                <div className="field">
                    <label htmlFor="senha">Senha</label>
                    <input type="password" name="senha" id="senha" value={senha} onChange={(e) => setSenha(e.target.value)}/>
                </div>
                <div className="actions">
                    <button type="submit">Entrar</button>
                </div>
            </form>
        </div>
    )
}

export default Login;