import React, {createContext, useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

import {api, createSession} from "../services/api.js"

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const recoveredUser = localStorage.getItem("user");
        const recoveredToken = localStorage.getItem("token");


        if(recoveredUser && recoveredToken){
            setUser(JSON.parse(recoveredUser));
            setToken(recoveredToken);
        }
        setLoading(false);

    }, [])

    const login = async (cpf, senha) => {
        const response = await createSession(cpf, senha);

        const loggedUser = response.data.user;            
        const loggedToken = response.data.token;


            setUser(loggedUser);
            setToken(loggedToken);

            localStorage.setItem("user", JSON.stringify(loggedUser));
            localStorage.setItem("token", loggedToken); 


            api.defaults.headers.Authorization = `Bearer ${token}`;

            navigate("/");
    
    }
    
    const logout = () => {
        api.defaults.headers.Authorization = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");     
        setUser(null);
        setToken(null);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{authenticated: !!user, user, loading, login, logout}}>
            {children}
        </AuthContext.Provider>
    )
}

export const getToken = () => localStorage.getItem("token");