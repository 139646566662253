import React from "react"

function CriarModalResumoVend({ props }) {
    return <>
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Resumo Vendedores</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col-4">Status</th>
                        <th scope="col-4">Qtd</th>
                        <th scope="col-1">Valor</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.from(Object(props), (item, index) => {
                            return <>

                                <tr>
                                    <td>{item.nome}</td>
                                    <td>{item.qtd}</td>
                                    <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlr)}</td>
                                </tr>

                            </>
                        })
                    }
                </tbody>
            </table>
        </div>

    </>
}

export default CriarModalResumoVend