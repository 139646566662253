import React, { useEffect, useState } from "react"

function CriarModalResumoPed({ props }) {

    return <>
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Resumo Status</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
            <table className="table table-striped ">
                <thead>
                    <tr>
                        <th scope="col-4">Status</th>
                        <th scope="col-1">Valor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Digitação</td>
                        <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props[0])}</td>
                    </tr>
                    <tr>
                        <td>Bloqueado</td>
                        <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props[2])}</td>
                    </tr>
                    <tr>
                        <td>Separação</td>
                        <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props[1])}</td>
                    </tr>
                    <tr>
                        <td>Expedição</td>
                        <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props[3])}</td>
                    </tr>
                    <tr>
                        <td>Despachado</td>
                        <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props[4])}</td>
                    </tr>
                    <tr>
                        <td>Cancelado</td>
                        <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props[5])}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </>
}

export default CriarModalResumoPed