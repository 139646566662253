import React from "react";

export function RelatorioVendasHead({ tipo, ordem }) {

    switch (parseInt(Object(tipo))) {
        case 1: return <>
            <tr>
                <th scope="col">Televendas</th>
                <th scope="col">Unidades</th>
                <th scope="col">Valor</th>
                <th scope="col">Participação</th>
            </tr>
        </>
        case 2: return <>
            <tr>
                <th scope="col">Vendedor</th>
                <th scope="col">Fabricante</th>
                <th scope="col">Unidades</th>
                <th scope="col">Valor</th>
                <th scope="col">Participação</th>
            </tr>
        </>
        case 3: return <>
            <tr>
                <th scope="col">Televendas</th>
                <th scope="col">Fabricante</th>
                <th scope="col">Unidades</th>
                <th scope="col">Valor</th>
                <th scope="col">Participação</th>
            </tr>
        </>
        default: return <>
            <tr>
                <th scope="col">Vendedor</th>
                <th scope="col">Unidades</th>
                <th scope="col">Valor</th>
                <th scope="col">Participação</th>
            </tr>
        </>
    }
}

export function RelatorioVendas({ props, tipo }) {

    switch (parseInt(Object(tipo))) {
        case 1: return <>
                <tr>
                    <td>{props.Televendas}</td>
                    <td>{props.qtd}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.vlr)}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'percent', maximumFractionDigits: 2}).format(props.part)}</td>
                </tr>
        </>
        case 2: return <>
                <tr>
                    <td>{props.Vendedor}</td>
                    <td>{props.Fabricante}</td>
                    <td>{props.qtd}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.vlr)}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'percent', maximumFractionDigits: 2}).format(props.part)}</td>
                </tr>
        </>
        case 3: return <>
                <tr>
                    <td>{props.Televendas}</td>
                    <td>{props.Fabricante}</td>
                    <td>{props.qtd}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.vlr)}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'percent', maximumFractionDigits: 2}).format(props.part)}</td>
                </tr>
        </>
        default:

            return <>
                <tr>
                    <td>{props.Vendedor}</td>
                    <td>{props.qtd}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.vlr)}</td>
                    <td>{Intl.NumberFormat('pt-BR', { style: 'percent', maximumFractionDigits: 2}).format(props.part)}</td>
                </tr>

            </>
    }
}
