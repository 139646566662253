import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/navbar.jsx";
import { api } from "../../services/api.js";
import CompProdut from "../../components/produtos/produtos.js";
import PaginationComponent from "../../components/pagination/pagination.js"
import CriarModal from "../../components/modal/modalProdutos.js";
import Estabelecimento from "../../components/combobox/estabelecimento.js";

const Produtos = () => {

    const [lProd, setListaProdutos] = useState([])
    const [lEstab, setListaEstabe] = useState([]);

    const [itensPorPage, setItensPorPage] = useState(100)
    const [currentePage, setCurrentPage] = useState(0)
    const pages = Math.ceil(lProd.length / itensPorPage)
    const startIndex = currentePage * itensPorPage
    const endIndex = startIndex + itensPorPage
    const currentItens = lProd.slice(startIndex, endIndex)

    const [tbproduto, setTbproduto] = useState("")
    const [tbfabricante, setTbfabricante] = useState("")
    const [selEstabe, setSelEstabe] = useState("")

    const [selProd, setSelProd] = useState("0")
    const [selDadosProd, setDadosProd] = useState([])

    const ConsultarProdutos = (ESTABE, COMESTOQUE, PRODUTO, FABRICANTE) => {
        let params = {
            cod_estabe: ESTABE,
            com_estoque: COMESTOQUE,
            produto: PRODUTO,
            fabricante: FABRICANTE
        }

        api.get("/listar/produtos", { params })
            .then((ret) => {
                setListaProdutos(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar Produtos! " + err);
            })
        setCurrentPage(0)

    }

    useEffect(() => {
        setDadosProd(lProd.filter(p => p.CODIGO === selProd).map((filtered, index) => filtered))
    }, [selProd, lProd])

    return <>
        <Navbar />

        <div className="container-fluid">
            <div className="d-flex justify-content-center">
                <h2>Produtos</h2>
            </div>

            <div className=" ms-4 me-4">
                <div className="ps-1 pt-1 pe-1 border border-black">
                    <div className="row mb-3">
                        <label htmlFor="inputProduto" className="col-sm-2 col-form-label">Produto</label>
                        <div className="col-md-4">

                            <input type="text" className="form-control" id="inputProduto" value={tbproduto} onChange={(e) => setTbproduto(e.target.value)} />
                        </div>


                        <label htmlFor="inputFabricante" className="col-sm-2 col-form-label">Fabricante</label>
                        <div className="col-md-4">

                            <input type="text" className="form-control" id="inputFabricante" value={tbfabricante} onChange={(e) => setTbfabricante(e.target.value)} />
                        </div>
                    </div>
                    <div className="row mb-2">
                        <label htmlFor="dropItensPorPage" className="col-sm-3 col-form-label">Itens por pagina</label>
                        <div className="col-md-2">
                            <select id="dropItensPorPage" value={itensPorPage} onChange={(e) => setItensPorPage(Number(e.target.value))}>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <label htmlFor="selectEstab" className="col-sm-2 col-form-label">Estado</label>
                        <div className="col-md-2">
                            <Estabelecimento props={lEstab} setSelEstabe={setSelEstabe} setListaEstabe={setListaEstabe} />
                        </div>
                        <div className="col-md-2">
                            <button type="submit" className="btn btn-sm btn-primary" onClick={(e) => ConsultarProdutos(selEstabe, 0, tbproduto, tbfabricante)}>Consultar</button>
                        </div>
                    </div>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Produto</th>
                            <th scope="col">Fabricante</th>
                            <th scope="col">Estoque</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentItens.map((props, index) => {
                                return <CompProdut
                                    props={props}
                                    setSelProd={setSelProd}
                                />
                            })}
                    </tbody>
                </table>
                <PaginationComponent pagina={pages} setCorrentPagina={setCurrentPage} />
            </div>
        </div>
        <div className="modal fade modal-lg" id="formModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <CriarModal props={selDadosProd} estabe={selEstabe}></CriarModal>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </>

}


export default Produtos