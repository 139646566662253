
function FabricanteBox(props) {
    return <>
        <div className="form-check">
            <input className="form-check-input" type="checkbox" value={props.CODIGO} id={props.CODIGO}/>
            <label className="form-check-label" htmlFor={props.CODIGO}>
                {props.FANTASIA}
            </label>
        </div>
    </>
}

export default FabricanteBox;