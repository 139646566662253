import React, {useContext} from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, AuthContext } from "./contexts/auth.jsx";

import Dashboard from "./pages/dashboard/dashboard.jsx";
import Login from "./pages/login/login.jsx";
import Pedidos from "./pages/pedidos/pedidos.jsx";
import RelatVendas from "./pages/relatorios.vendas/relatoriovendas.jsx";
import Produtos from "./pages/produtos/produtos.jsx";


const Rotas = () => {
    const Private = ({children}) => {
        const {authenticated, loading} = useContext(AuthContext);
        if (loading)
        {
            return <div className="loading">Carregando...</div>
        }

        if(!authenticated) {
            return <Navigate to="/login"/>
        }
        
        return children;
    }
    
    return (   
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route exct path="/login" element={<Login />} />
                    <Route exct path="/pedidos" element={<Private><Pedidos /></Private>} />
                    <Route exct path="/" element={<Private><Dashboard /></Private>} />
                    <Route exct path="/relatoriovendas" element={<Private><RelatVendas /></Private>} />
                    <Route exct path="/produtos" element={<Produtos />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
};

export default Rotas;