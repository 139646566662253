import React from "react";


function Ped({ props, setSelPedido }) {
    return <tr>
            <th scope="row">{props.Numero}</th>
            <td>{props.Cod_Estabe}</td>
            <td>{new Date(props.Dat_Pedido).toLocaleDateString('pt-BR')}</td>
            <td>{props.L_CodVendedor}</td>
            <td>{props.L_CodVendTlmkt}</td>
            <td>{props.C_DesCliente}</td>
            <td>{props.C_DesFase}</td>
            <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.Vlr_LiqItens)}</td>
            <td>
                <div className="dropdown">
                    <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Opções    
                    </button> 
                    {/*<button type="button" value={props.CODIGO} className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#formModalDetalhes" onClick={(e) => setSelPedido(Number(e.target.value))}>Detalhar</button> */}
                    <ul className="dropdown-menu">
                        <li className="dropdown-item" href="#">Detalhar</li>
                        <li className="dropdown-divider"></li>
                        <li className="dropdown-item" href="#">Fechar</li>
                        <li className="dropdown-divider"></li>
                        <li className="dropdown-item" href="#">Excluir</li>
                    </ul>
                </div>
            </td>
        </tr>
    
}

export default Ped;