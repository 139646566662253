import { useEffect, useState } from "react";
import { api } from "../../services/api.js"
import Navbar from "../../components/navbar/navbar.jsx";
import PaginationComponent from "../../components/pagination/pagination.js"
import { EstabelecimentoTodos } from "../../components/combobox/estabelecimento.js";
import { VendedoresTodos } from "../../components/combobox/vendedores.js";
import { TelevendasTodos } from "../../components/combobox/televendas.js";
import CompPedidos from "../../components/pedidos/pedido.jsx"
import CompBtResumoVendedor from "../../components/pedidos/btresumovendedor.js"
import "./pedidos.css"
import GrpClientes from "../../components/combobox/grpcliente.js";
import CriarModalResumoPed from "../../components/modal/pedidos/modalReumo.js";
import CriarModalResumoVend from "../../components/modal/pedidos/modalResumoVendedor.js";

function Pedidos() {

    const [lEstab] = useState([])

    const [total, setTotal] = useState(0)
    const [qtdPed, setQtdPed] = useState(0)
    const [selStatus, setStatus] = useState(0)
    const [lPedidos, setListaPedidos] = useState([])
    const [selEstabe, setSelEstabe] = useState("")
    const [selVende, setSelVende] = useState("")
    const [selTele, setSelTele] = useState("")
    const [selGrpCli, setSelGrpCli] = useState("")
    const [selDataIni, setSelDataIni] = useState(new Date())
    const [selDataFim, setSelDataFim] = useState(new Date())
    const [selWMS, setSelWMS] = useState("")
    const [selPocom, setSelPocom] = useState("")
    const [selCliente, setSelCliente] = useState("")
    const [selProduto, setSelProduto] = useState("")
    const [selNota, setSelNota] = useState("")
    const [selPedido, setSelPedido] = useState("")

    //paginação
    const [pedidosPorPage, setPedidosPorPage] = useState(20)
    const [currentePage, setCurrentPage] = useState(0)
    const pages = Math.ceil(lPedidos.length / pedidosPorPage)
    const startIndex = currentePage * pedidosPorPage
    const endIndex = startIndex + pedidosPorPage
    const currentPedidos = lPedidos.slice(startIndex, endIndex)

    //variaveis resumo
    const [digitacao, setDigitacao] = useState(0)
    const [separacao, setSeparacao] = useState(0)
    const [bloqueado, setBloqueado] = useState(0)
    const [expedicao, setExpedicao] = useState(0)
    const [despachado, setDespachado] = useState(0)
    const [cancelado, setCancelado] = useState(0)

    //variaveis resumo vendedor    
    const [resumoVende, setResumoVende] = useState([])

    const resumirVendedor = () => {
        let ret = []
        let valor = 0
        let achou = false

        for (let i = 0; i < lPedidos.length; i++) {
            if (lPedidos[i].Tip_Faturamento = 'FAT') {
                switch (lPedidos[i].C_DesFase) {
                    case '(1) Dig': valor = lPedidos[i].Vlr_LiqItens; break;
                    case '(2) Sep': valor = lPedidos[i].Vlr_LiqItens; break;
                    case '(2) Blq': valor = lPedidos[i].Vlr_LiqItens; break;
                    case '(3) Exp': valor = lPedidos[i].Vlr_LiqItens; break;
                    case '(4) Des': valor = lPedidos[i].Vlr_LiqItens; break;
                    case '(X) Can': valor = 0; break;
                    default: valor = 0;
                }
            }
            else valor = 0

            if (valor > 0) {
                achou = false
                for (let x = 0; x < ret.length; x++) {
                    if (lPedidos[i].L_CodVendedor === ret[x].nome) {
                        achou = true
                        ret[x].vlr += valor
                        ret[x].qtd += 1
                        break
                    }
                }
                if (!achou) {
                    ret.push({ nome: lPedidos[i].L_CodVendedor, qtd: 1, vlr: lPedidos[i].Vlr_LiqItens })
                }
            }
        }
        ret = ret.sort((a, b) => a.nome > b.nome ? 1 : -1)
        setResumoVende(ret)
    }

    const addDays = (days, date, setDate) => {
        const newDate = new Date(date.getTime());
        newDate.setDate(date.getDate() + days);
        setDate(newDate.toISOString().slice(0, 10));
    };

    const CalcularTotais = () => {
        let tot = 0;
        let qtd = 0;

        let dig = 0
        let blo = 0
        let sep = 0
        let exp = 0
        let des = 0
        let can = 0
        let err = 0

        lPedidos.map((p, index) => {

            switch (p.C_DesFase) {
                case '(1) Dig': dig += p.Vlr_LiqItens; break;
                case '(2) Sep': sep += p.Vlr_LiqItens; break;
                case '(2) Blq': blo += p.Vlr_LiqItens; break;
                case '(3) Exp': exp += p.Vlr_LiqItens; break;
                case '(4) Des': des += p.Vlr_LiqItens; break;
                case '(X) Can': can += p.Vlr_LiqItens; break;
                default: err += p.Vlr_LiqItens;
            }

            return (
                tot += p.Vlr_LiqItens,
                qtd = index + 1
            )
        })

        setTotal(tot);
        setQtdPed(qtd);
        setDigitacao(dig)
        setBloqueado(blo)
        setExpedicao(exp)
        setSeparacao(sep)
        setDespachado(des)
        setCancelado(can)
    }

    const ConsultarPedidos = async () => {

        let params = {
            cod_estabe: selEstabe,
            status: selStatus,
            produto: selProduto,
            wms: selWMS,
            dtInicio: selDataIni,
            dtFim: selDataFim,
            grpcli: selGrpCli,
            codcli: selCliente,
            nota: selNota,
            vende: selVende,
            tele: selTele,
            id_polcom: selPocom
        }

        await api.get("/listar/pedidos", { params })
            .then((ret) => {
                setListaPedidos(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar Pedidos! " + err)
            })


    }

    useEffect(() => CalcularTotais())

    useEffect(() => {
        if (lEstab.length === 0) {
            ConsultarPedidos()
        }
        addDays(-30, new Date(), setSelDataIni)
    }, [lEstab])

    useEffect(() => {
        setSelDataFim(new Date(Date.now()).toISOString().slice(0, 10))
    }, [selDataFim])

    return <>
        <Navbar />
        <div className="container-fluid">
            <div className="d-flex justify-content-center">
                <h2>Pedidos</h2>
            </div>
            <div className=" ms-4 me-4">
                <div className="ps-1 pt-1 pe-1 border border-black">
                    <div className="collapse" id="navbarToggleExternalContent" data-bs-theme="green">
                        <div className="p-2">
                            <div className="row mb-2">
                                <div className="col-md-2">
                                    <label htmlFor="inputProduto" className="me-2">Status</label>
                                    <select name="selStatus" id="selStatus" value={selStatus} onChange={(e) => setStatus(e.target.value)}>
                                        <option value="0">Pendente</option>
                                        <option value="1">Digitação</option>
                                        <option value="2">Bloqueado</option>
                                        <option value="3">Separação</option>
                                        <option value="4">Expedição</option>
                                        <option value="5">Despachado</option>
                                        <option value="6">Cancelado</option>
                                        <option value="7">Todos</option>
                                    </select>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="dtInicio" className="me-2">Data Inicial</label>
                                    <input type="date" name="dtInicio" id="dtInicio" value={selDataIni} onChange={(e) => setSelDataIni(e.target.value)} />
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="dtInicio" className="me-2">Data Final</label>
                                    <input type="date" name="dtFim" id="dtFim" value={selDataFim} onChange={(e) => setSelDataFim(e.target.value)} />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="selWMS" className="me-2">WMS</label>
                                    <select name="selWMS" id="selWMS" value={selWMS} onChange={(e) => setSelWMS(e.target.value)}>
                                        <option value="">TODOS</option>
                                        <option value="1">PENDENTE</option>
                                        <option value="2">IMPRESSO</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-md-3">
                                    <label htmlFor="selectVendedor" className="me-2">Vendedor</label>
                                    <VendedoresTodos setSelVende={setSelVende} />
                                </div>

                                <div className="col-md-3">
                                    <label htmlFor="selectTele" className="me-2">Televendas</label>
                                    <TelevendasTodos setSelTele={setSelTele} />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="selectEstab" className="me-2">Estado</label>
                                    <EstabelecimentoTodos setSelEstabe={setSelEstabe}></EstabelecimentoTodos>
                                </div>
                            </div>
                            <div className="row mb-0">
                                <div className="col-md-3">
                                    <label htmlFor="tbCliente" className="me-2">Cliente</label>
                                    <input type="text" name="tbCliente" id="tbCliente" value={selCliente} onChange={(e) => setSelCliente(e.target.value)} />
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="selectTele" className="me-2">Grp Cliente</label>
                                    <GrpClientes setSelGrpCli={setSelGrpCli}></GrpClientes>
                                </div>
                                <div className="col-md-3">
                                    <label htmlFor="tbProduto" className="me-2">Produto</label>
                                    <input type="text" name="tbProduto" id="tbProduto" value={selProduto} onChange={(e) => setSelProduto(e.target.value)} />
                                </div>
                                <div className="col-md-2">
                                    <label htmlFor="tbNF" className="me-2">Nº Nota</label>
                                    <input type="text" name="tbNF" id="tbNF" value={selNota} onChange={(e) => setSelNota(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <nav className="navbar navbar">
                        <div className="container-fluid md-1">

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-filter" viewBox="0 0 16 16">
                                    <path d="M6 10.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-2-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z" />
                                </svg>
                            </button>
                            <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#formModalResumo">Resumo Status</button>
                            <CompBtResumoVendedor clic={resumirVendedor}></CompBtResumoVendedor>
                            <div>
                                <label id="lbTotal">Pedidos : {qtdPed} - Total : {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</label>
                            </div>
                            <button type="submit" className="btn btn-lg btn-primary" onClick={(e) => ConsultarPedidos()}>Consultar</button>

                        </div>
                    </nav>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Est.</th>
                            <th scope="col">Dt. Ped.</th>
                            <th scope="col">Vendedor</th>
                            <th scope="col">Operador</th>
                            <th scope="col">Cliente</th>
                            <th scope="col">Fase</th>
                            <th scope="col">Total</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {

                            currentPedidos.map((props, index) => {
                                return <CompPedidos
                                    props={props}
                                    setSelPedido={setSelPedido}
                                />
                            })}
                    </tbody>
                </table>
                <PaginationComponent pagina={pages} setCorrentPagina={setCurrentPage} />
            </div>
        </div>
        <div className="modal fade modal-lg" id="formModalResumo" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">

                    <CriarModalResumoPed props={[digitacao, separacao, bloqueado, expedicao, despachado, cancelado]}></CriarModalResumoPed>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade modal-lg" id="formModalResumoVendedor" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <CriarModalResumoVend props={resumoVende}></CriarModalResumoVend>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade modal-lg" id="formModalDetalhes" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </>;
}

export default Pedidos;