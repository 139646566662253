import React, { useEffect, useState } from "react";
import { api } from "../../services/api.js"

function Televendas({props, setSelTele }) {
    const [lTele, setListaTele] = useState([])
    
    useEffect(() => {
        api.get("/listar/televenda")
            .then((ret) => {
                setListaTele(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar televendas! " + err);
            })
    }, [props])

    return (
        <select name="selectTelevendas" id="selectTelevendas" onChange={(e) => setSelTele(e.target.value)}>
            {lTele.map((e, index) => {
                return <option value={e.CODIGO} key={index}>{e.TELEVENDA}</option>
            })}
        </select>
    )
}

export function TelevendasTodos({ setSelTele }) {
    const [lTele, setListaTele] = useState([])
    
    useEffect(() => {
        api.get("/listar/televenda")
            .then((ret) => {
                setListaTele(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar televendas! " + err);
            })
    }, [])

    return (
        <select name="selectTelevendas" id="selectTelevendas" onChange={(e) => setSelTele(e.target.value)}>
            <option value="">TODOS</option>
            {lTele.map((e, index) => {
                return <option value={e.CODIGO} key={index}>{e.TELEVENDA}</option>
            })}
        </select>
    )
}

export default Televendas