import React from "react"

const PaginationComponent = ({ pagina, setCorrentPagina }) => {
    return (
        <div>
            {Array.from(Array(pagina), (item, index) => {
                return <button key={index} value={index} onClick={(e) => setCorrentPagina(Number(e.target.value))} type="button" className="btn btn-info m-1">{index + 1}</button>
            })}
        </div>
    )
}

export default PaginationComponent