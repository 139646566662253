import axios from "axios";
import { getToken } from "../contexts/auth";

export const api = axios.create({
   baseURL: "http://189.124.135.18:3001"
 //  baseURL: "http://localhost:3001"
})

api.interceptors.request.use(async config => {
    const token = getToken();
    if (token) {
      config.headers = {'Content-Type':"application/json",'x-access-token': token}
    }
    return config;
  });

// Response interceptor
api.interceptors.response.use(  
  (response) => {
    // Modify the response data here (e.g., parse, transform)
      return response;
  },
  (error) => {
    if (error.response && [501, 403].includes(error.response.status)) {
      console.error('Redirected to login!');
      window.location.href = '/login';
  }
  else
      return Promise.reject(error);
  }
);

export const createSession = async (cpf, senha) => {
    return api.post("/login", {cpf, senha});
}
