import React from "react";

function btResumoVendedor({ clic }) {
    const recoveredUser = localStorage.getItem("user");
    const perfilPermissoes = [1, 2, 3, 4, 11]
    let user = []
    if (recoveredUser) {
        user = JSON.parse(recoveredUser)
    }
    if (perfilPermissoes.indexOf(parseInt(user.perfil)) > -1)
    {        
        return <>
        <button type="button" className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#formModalResumoVendedor" onClick={clic}>Resumo Vendedor</button>
    </>    
    }
    else {
        return <></>
    }
    
}

export default btResumoVendedor;