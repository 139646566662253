import React from "react";

function Produt ({props, setSelProd}){
    return (
            <tr>
            <th scope="row">{props.CODIGO}</th>
            <td>{props.DESCRICAO}</td>
            <td>{props.FABRICANTE}</td>
            <td>{props.ESTOQUE}</td>
            <td>
                <button type="button" value={props.CODIGO} className="btn btn-sm btn-info" data-bs-toggle="modal" data-bs-target="#formModal" onClick={(e) => setSelProd(Number(e.target.value))}>Detalhar</button>
            </td>
        </tr>
    )
}

export default Produt;