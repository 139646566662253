import React, { useEffect, useState } from "react";
import { api } from "../../services/api.js"


function GrpClientes({ setSelGrpCli }) {
    const [lGrpCli, setListaGrpCli] = useState([])
    
    useEffect(() => {
        api.get("/listar/grpcli")
            .then((ret) => {
                setListaGrpCli(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar Grupo de Clientes! " + err);
            })
    })

    return (
        <select name="selectGrpCli" id="selectGrpCli" onChange={(e) => setSelGrpCli(e.target.value)}>
            <option value="">TODOS</option>
            {lGrpCli.map((e, index) => {
                return <option value={e.CODIGO} key={index}>{e.GRUPO}</option>
            })}
        </select>
    )
}

export default GrpClientes