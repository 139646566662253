import React, { useContext} from "react";
import { Link } from "react-router-dom";

import { AuthContext } from "../../contexts/auth";

import { menuPrincipal, menuEstoque, menuFaturamento, menuGerencial, menuGestaoFarma, menuRelatorios } from "./menus.ts";

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  }

  return <>
    <nav className="navbar bg-primary navbar-expand-lg" data-bs-theme="dark">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
          <span className="navbar-toogler">Menu</span>
        </button>        

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <Link className="navbar-brand" to="/">Home</Link>
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {menuPrincipal.map((props, index) => {
              //console.log(user.master + user.acesso.split(",") + props.id + acessos.indexOf(props.id.toString()))

              return (
                <li className="nav-item dropdown" key={props.title}>
                  {(user.master || user.acesso.split(",").indexOf(props.id.toString()) > -1) && (
                    <a className="nav-link dropdown-toggle active" href="/" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      {props.title}
                    </a>)
                  }
                  <ul className="dropdown-menu">
                    {(props.itens === "Estoque") && (
                      menuEstoque?.map((itmenu, index) => {
                        if (user.master || user.acesso.split(",").indexOf(itmenu.id.toString()) > -1) {
                          if (itmenu.classe === "dropdown-divider") {
                            return <li key={itmenu.id}><hr className={itmenu.classe} /></li>
                          } else {
                            return <li key={itmenu.id}><Link className={itmenu.classe} to={itmenu.href}>{itmenu.title}</Link></li>
                          }
                        }
                        else
                        {
                          return <></>
                        }
                      })
                      
                    )}
                    {(props.itens === "Faturamento") && (
                      menuFaturamento?.map((itmenu, index) => {
                        if (user.master || user.acesso.split(",").indexOf(itmenu.id.toString()) > -1) {
                          if (itmenu.classe === "dropdown-divider") {
                            return <li key={itmenu.id}><hr className={itmenu.classe} /></li>
                          } else {
                            return <li key={itmenu.id}><Link className={itmenu.classe} to={itmenu.href}>{itmenu.title}</Link></li>
                          }
                        }
                        else
                        {
                          return <></>
                        }
                      })
                    )}
                    {(props.itens === "Relatórios") && (
                      menuRelatorios?.map((itmenu, index) => {
                        if (user.master || user.acesso.split(",").indexOf(itmenu.id.toString()) > -1) {
                          if (itmenu.classe === "dropdown-divider") {
                            return <li key={itmenu.id}><hr className={itmenu.classe} /></li>
                          } else {
                            return <li key={itmenu.id}><Link className={itmenu.classe} to={itmenu.href}>{itmenu.title}</Link></li>
                          }
                        }
                        else
                        {
                          return <></>
                        }
                      })
                    )}
                    {(props.itens === "Gerencial") && (
                      menuGerencial.map((itmenu, index) => {
                        if (itmenu.classe === "dropdown-divider") {
                          return <li key={itmenu.id}><hr className={itmenu.classe} /></li>
                        } else {
                          return <li key={itmenu.id}><Link className={itmenu.classe} to={itmenu.href}>{itmenu.title}</Link></li>
                        }
                      })
                    )}
                    {(props.itens === "Gestão farma") && (
                      menuGestaoFarma.map((itmenu, index) => {
                        if (itmenu.classe === "dropdown-divider") {
                          return <li key={itmenu.id}><hr className={itmenu.classe} /></li>;
                        } else {
                          return <li key={itmenu.id}><Link className={itmenu.classe} to={itmenu.href}>{itmenu.title}</Link></li>;
                        }
                      })
                    )}
                  </ul>
                </li>
              )
            })}
          </ul>
          <form className="d-flex">
            <label className="form-control me-2">Bem vindo : {user.nome}</label>
          </form>
          <form className="d-flex px-2">
            <button type="button" className="btn btn-warning" onClick={handleLogout}>Sair</button>
          </form>
        </div>
      </div>
    </nav>
  </>
}

export default Navbar;