import React, { useEffect, useState } from "react";
import { api } from "../../services/api.js"

function Estabelecimento({props, setSelEstabe }) {
    const [lEstab, setListaEst] = useState([])
    
    useEffect(() => {
        api.get("/listar/estabelecimento")
            .then((ret) => {
                setListaEst(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar Estabelecimentos! " + err);
            })
    }, [props])

    return (
        <select name="selectEstab" id="selectEstab" onChange={(e) => setSelEstabe(e.target.value)}>
            {lEstab.map((e, index) => {
                if (index === 0)
                {
                    setSelEstabe(e.Cod_Estabe)
                }
                return <option value={e.Cod_Estabe} key={index}>{e.Des_Estabe}</option>
            })}
        </select>
    )
}

export function EstabelecimentoTodos({setSelEstabe }) {
    const [lEstab, setListaEstabe] = useState([])
    
    useEffect(() => {
        api.get("/listar/estabelecimento")
            .then((ret) => {
                setListaEstabe(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar Estabelecimentos! " + err);
            })
    }, [])

    return (
        <select name="selectEstab" id="selectEstab" onChange={(e) => setSelEstabe(e.target.value)}>
            <option value="">TODOS</option>
            {lEstab.map((e, index) => {
                return <option value={e.Cod_Estabe} key={index}>{e.Des_Estabe}</option>
            })}
        </select>
    )
}

export default Estabelecimento