import React, { useEffect, useState } from "react"
import { api } from "../../services/api.js"

function CriarModal({ props, estabe }) {

    const [listaPolitica, setListaPolitica] = useState([])
    const [cod_pr, setCod_Pr] = useState(0)
    const [estab, setEstab] = useState("")
    const [lEstab, setLEstabe] = useState([])

    useEffect(() => {
        setLEstabe(localStorage.getItem("user"))
        Array.from(Object(props), (item, index) => {

            setEstab(estabe)
            setCod_Pr(item.CODIGO)

            return <></>
        })
    }, [props, estabe])


    useEffect(() => {
        if (cod_pr > 0) {

            if (estab === "") {
                setEstab(Array.from(Object(lEstab), (item, index) => {
                    return item.Cod_Estabe
                }))
            }

            let params = {
                cod_estabe: estab,
                cod_prod: cod_pr
            }

            api.get("/listar/politicas", {
                params

            })
                .then((ret) => {
                    setListaPolitica(ret.data)
                    setCod_Pr(0)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [cod_pr, estab])

    return (<>{
        Array.from(Array(props), (item, index) => {
            if (item[0] !== undefined) {
                const dtValidade = new Date(item[0].VALIDADE.substring(0, 19))
                return <>

                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">{item[0].CODIGO + " : " + item[0].DESCRICAO}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th scope="row">EAN</th>
                                    <td>{item[0].Cod_EAN}</td>
                                    <th scope="row">FABRICANTE</th>
                                    <td>{item[0].FABRICANTE}</td>
                                </tr>
                                <tr>
                                    <th scope="row">ESTOQUE</th>
                                    <td>{item[0].ESTOQUE + " UND"}</td>
                                    <th scope="row">PREÇO TABELA</th>
                                    <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item[0].VENDA)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">QTD EMBARQUE</th>
                                    <td>{item[0].Qtd_Embalagem + " UND"}</td>
                                    <th scope="row">VALIDADE</th>
                                    <td>{Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(dtValidade)}</td>
                                </tr>
                                <tr>
                                    <th scope="row">NCM</th>
                                    <td>{item[0].NCM}</td>
                                    <th scope="row">CEST</th>
                                    <td>{item[0].Cod_CEST}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h4 className="d-flex justify-content-center">CONDIÇÕES</h4>
                        <table className="table table-striped ">
                            <thead>
                                <tr>
                                    <th scope="col-4">Política</th>
                                    <th scope="col-1">Qtd</th>
                                    <th scope="col-1">Valor</th>
                                    <th scope="col-2">Lote</th>
                                    <th scope="col-2">Validade</th>
                                    <th scope="col-1">Est.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listaPolitica.map((item, index) => {
                                    return <>
                                        <tr>
                                            <td>{item.Politica}</td>
                                            <td>{item.Qtd_Min}</td>
                                            <td>{Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.Preco)}</td>
                                            <td>{item.Cod_Lote}</td>
                                            <td>{item.Dat_Vencim}</td>
                                            <td>{item.Qtd_Saldo}</td>
                                        </tr>
                                    </>
                                })}
                            </tbody>
                        </table>
                    </div>
                </>
            } else {
                return <></>
            }

        })}</>)
}

export default CriarModal