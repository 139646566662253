export const menuPrincipal = [
    {id: 27, title: "Estoque", href: "#", itens: "Estoque" },
    {id: 44, title: "Faturamento", href: "#", itens: "Faturamento" },
    {id: 10, title: "Relatórios", href: "#", itens: "Relatórios" },
    {id: 23, title: "Gerencial", href: "#", itens: "Gerencial" },
    {id: 35, title: "Gestão farma", href: "#", itens: "Gestão farma" }
]

export const menuEstoque = [
    {id: 1,  title: "Produtos", href: "/produtos", drop: false, dropPai: "Estoque", classe: "dropdown-item" },
    {id: 29,  title: "Tranf. Endereço", href: "transfend", drop: false, dropPai: "Estoque", classe: "dropdown-item" },
    {id: 33,  title: "Rastreamento Produto/Lote", href: "rastreamento", drop: false, dropPai: "Estoque", classe: "dropdown-item" },    
    {id: 200,  title: "hr1", href: "#", drop: false, dropPai: "Estoque", classe: "dropdown-divider" },
    {id: 28,  title: "Entrada de Nota", href: "entradanota", drop: false, dropPai: "Estoque", classe: "dropdown-item" },
    {id: 201,  title: "hr2", href: "#", drop: false, dropPai: "Estoque", classe: "dropdown-divider" },
    {id: 30,  title: "Auditória", href: "auditoria", drop: false, dropPai: "Estoque", classe: "dropdown-item" },
    {id: 31,  title: "Balanço", href: "balanco", drop: false, dropPai: "Estoque", classe: "dropdown-item" },
    {id: 202,  title: "hr3", href: "#", drop: false, dropPai: "Estoque", classe: "dropdown-divider" },
    {id: 32,  title: "Painel de Conferência", href: "panielconf", drop: false, dropPai: "Estoque", classe: "dropdown-item" }
]

export const menuFaturamento = [    
    {id: 4,  title: "Pedidos", href: "/pedidos", drop: false, dropPai: "Faturamento", classe: "dropdown-item" },    
    {id: 14,  title: "Pedidos Eletrônicos(OL)", href: "pedeletronico", drop: false, dropPai: "Faturamento", classe: "dropdown-item" },    
    {id: 203,  title: "hr4", href: "#", drop: false, dropPai: "Faturamento", classe: "dropdown-divider" },
    {id: 15,  title: "Divisão de produtos", href: "divprod", drop: false, dropPai: "Faturamento", classe: "dropdown-item" },
    {id: 34,  title: "Conferência Caixa Fechada", href: "confcxfechada", drop: false, dropPai: "Faturamento", classe: "dropdown-item" }
]

export const menuRelatorios = [    
    {id: 11,  title: "Vendas", href: "/relatoriovendas", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 12,  title: "Acompanhamento", href: "metas", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 13,  title: "Parcerias", href: "metasparcerias", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 204,  title: "hr5", href: "#", drop: false, dropPai: "Relatórios", classe: "dropdown-divider" },
    {id: 16,  title: "Política por Cliente", href: "polclie", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 17,  title: "Painel de Clientes por política", href: "painelpolclie", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 205,  title: "hr6", href: "#", drop: false, dropPai: "Relatórios", classe: "dropdown-divider" },
    {id: 18,  title: "Ficha de clientes", href: "fichaclie", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 19, title: "Títulos", href: "titulos", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 20, title: "XML", href: "xml", drop: false, dropPai: "Relatórios", classe: "dropdown-item" },
    {id: 22, title: "Comissão por Liquidez", href: "painelpolclie", drop: false, dropPai: "Relatórios", classe: "dropdown-item" }
]

export const menuGerencial = [
    {id: 24, title: "Verba", href: "verba", drop: false, dropPai: "Gerencial", classe: "dropdown-item" },
    {id: 25, title: "Lançamento Metas/Campanhas", href: "metasadm", drop: false, dropPai: "Gerencial", classe: "dropdown-item" },
    {id: 26, title: "Parcerias", href: "parceriasadm", drop: false, dropPai: "Gerencial", classe: "dropdown-item" },
    {id: 206, title: "hr7", href: "#", drop: false, dropPai: "Gerencial", classe: "dropdown-divider" },
    {id: 42, title: "Cadastrar Usuário", href: "usuarios", drop: false, dropPai: "Gerencial", classe: "dropdown-item" },
    {id: 43, title: "Cadastrar Perfil", href: "perfil", drop: false, dropPai: "Gerencial", classe: "dropdown-item" }
]

export const menuGestaoFarma = [ 
    {id: 37, title: "Temperatura/Umidade", href: "tempumid", drop: false, dropPai: "Gestão farma", classe: "dropdown-item" },
    {id: 38, title: "Temperatura/Umidade (Entrada)", href: "tempumident", drop: false, dropPai: "Gestão farma", classe: "dropdown-item" },    
]