import React, { useEffect, useState } from "react";
import { api } from "../../services/api.js"

function Vendedores({props, setSelVende }) {
    const [lVend, setListaVende] = useState([])
    
    useEffect(() => {
        api.get("/listar/vendedor")
            .then((ret) => {
                setListaVende(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar vendedor! " + err);
            })
    }, [props])

    return (
        <select name="selectVendedor" id="selectVendedor" onChange={(e) => setSelVende(e.target.value)}>
            {lVend.map((e, index) => {
                return <option value={e.CODIGO} key={index}>{e.VENDEDOR}</option>
            })}
        </select>
    )
}

export function VendedoresTodos({setSelVende }) {
    const [lVend, setListaVende] = useState([])
    
    useEffect(() => {
        api.get("/listar/vendedor")
            .then((ret) => {
                setListaVende(ret.data);
            })
            .catch((err) => {
                console.log("Erro listar vendedor! " + err);
            })
    }, [])

    return (
        <select name="selectVendedor" id="selectVendedor" onChange={(e) => setSelVende(e.target.value)}>
            <option value="">TODOS</option>
            {lVend.map((e, index) => {
                return <option value={e.CODIGO} key={index}>{e.VENDEDOR}</option>
            })}
        </select>
    )
}

export default Vendedores